@if ($theme == dark) {
   
    .navbar-top.border-bottom.navbar {
        border-color: darken($white, 60%) !important;
    }

    .sidenav.navbar-vertical.fixed-left.navbar-expand-xs.navbar.navbar {
        background: $black;
    }

    .nav-pills {

        .nav-link {
            background: $black;
        }

    }
    
} @else {

    .sidenav.navbar-vertical.fixed-left.navbar-expand-xs.navbar.navbar {
        background: $white;
    }

}
