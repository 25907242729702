
@keyframes shadow-pulse {
    0% {
         box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);

    }
    100% {

         box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }
}


.btn-pulse {
   animation: shadow-pulse 1s infinite;
}

@if ($theme == dark) {

     .btn.btn-neutral {
        background: lighten($black, 5%);
        border-color: lighten($black, 1%);
        color: $white;
     }
 
 }
 