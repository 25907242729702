
@media(max-width: 768px) {
    .react-bootstrap-table-pagination-total {
         display: none;
    }
}

.table {

    td {
        vertical-align: middle;
    }

    .aligned-image {
        position: relative;
        margin-top: -0px;

    }

}


@if ($theme == dark) {
    .page-item.disabled .page-link {

        background: $gray-900;
        border-color: $blackOffset;
    }
}
