.navbar-horizontal .navbar-brand img {
    height: 80px;
}

.table-vertical-align {

    td {
        vertical-align: middle;
    }

    .aligned-image {
        position: relative;
        margin-top: -0px;

    }

}

.circleLoader {

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
    z-index: 999999;
    text-align: center;
    color: white;
    display: flex;

    .loader-wrapper {
        align-self: center;
        align-content: center;
        text-align: center;
        width: 100%;
    }

    p {
        font-size: 2rem;
        font-weight: bold;
    }
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    .loader {

        // margin: 60px auto;
        font-size: 10px;
        position: absolute;
        left: calc(50% - 70px);
        margin-top: -100px;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

}

.popup {

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    display: flex;

    .popup-background {

        background: rgba(0,0,0,.6);
        z-index: 1050;

        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .popup-content {
        position: relative;
        margin: 0.5rem;
        overflow-y: scroll;
        max-height: calc(100vh - 1rem);
        z-index: 1100;
        width: calc(100% - 2rem);
        margin-right: auto;
        margin-left: auto;
        -webkit-overflow-scrolling: touch;
        align-self: center;
        overflow-y: auto;

    }
    @media (min-width: 576px) {
        .popup-content {
            max-width: 540px;
        }
        @media (min-width: 768px) {
            .popup-content {
                max-width: 720px;
            }
        }
        @media (min-width: 992px) {
            .popup-content {
                max-width: 960px;
            }
        }
        @media (min-width: 1200px) {
            .popup-content {
                max-width: 1140px;
            }
        }

    }

    .popup-body {
        padding: 1.5rem;
        background: white;
        border-radius: 5px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        /* Lets it scroll lazy */

        .close {
            position: fixed;
            top: 20px;
            right: 20px;
            font-size: 1.2rem;
            z-index: 9999;
            color: black;
        }
    }

    .container {
        padding-right: 0;
        padding-left: 0;
        width: calc(100% - 30px);
        margin: auto;
    }

}

.noScroll {
    /* ...or body.dialogShowing */
    overflow: hidden !important;
}

.payment-content {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 99999999999;
    overflow-y: auto;

    .col-left {
        background: white;
        padding: 4rem;
        min-height: 100vh;

        .col-wrapper {
            max-width: 600px;
            margin-left: auto;
        }

    }

    .col-right {
        padding: 4rem;

        .col-wrapper {
            max-width: 400px;
            margin-right: auto;
        }

    }
    @media(max-width: 993px) {

        .col-left,
        .col-right {
            padding: 4rem 2rem;

            .col-wrapper {
                max-width: 100%;
            }

        }
    }
    @media(max-width: 600px) {
        .icons,
        .logo {
            width: 100% !important;
            text-align: left !important;
            margin-right: auto;
        }
    }

    .payment-body {
        padding: 0;

    }
}
@media (max-width: 991.98px) {

    .navbar-horizontal .navbar-collapse .collapse-brand img {
        // height: auto;
    }

}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    30% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.animate-360-pause {
    animation: rotate 3s infinite;
}




@keyframes pulseBtnSuccess {
    /* You could think of as "step 1" */
    0% {
      background: $green;
      border-color: $green;
      box-shadow: $btn-box-shadow;
      border-radius: $btn-border-radius;
    }
    20% {
        background: lighten($green, 10%);
        border-color: $green;
        box-shadow: 0px 0px 0px 2px rgba(45,26,124,.15);
        border-radius: $btn-border-radius * 2;

    }
    35% {
        background: $green;
        border-color: $green;
        box-shadow: $btn-box-shadow;
        border-radius: $btn-border-radius;
    }
    50% {
        background: $green;
        border-color: $green;
        box-shadow: $btn-box-shadow;
        border-radius: $btn-border-radius;
    }
    100% {
        background: $green;
        border-color: $green;
        box-shadow: $btn-box-shadow;
    }
}

.btn.btn-success-pulse {
    animation: pulseBtnSuccess 7s infinite;
}


@keyframes pulseBtnInfo {
    /* You could think of as "step 1" */
    0% {
      background: $teal;
      border-color: $teal;
      box-shadow: $btn-box-shadow;
      border-radius: $btn-border-radius;
    }
    20% {
        background: lighten($teal, 10%);
        border-color: $teal;
        box-shadow: 0px 0px 0px 2px rgba(17,205,239,.15);
        border-radius: $btn-border-radius * 2;

    }
    35% {
        background: $teal;
        border-color: $teal;
        box-shadow: $btn-box-shadow;
        border-radius: $btn-border-radius;
    }
    50% {
        background: $teal;
        border-color: $teal;
        box-shadow: $btn-box-shadow;
        border-radius: $btn-border-radius;
    }
    100% {
        background: $teal;
        border-color: $teal;
        box-shadow: $btn-box-shadow;
    }
}

.btn.btn-info-pulse {
    animation: pulseBtnInfo 7s infinite;
}

@keyframes pulseContact {
    /* You could think of as "step 1" */
    0% {
      box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;;
    }

    50% {
        box-shadow: 0 8px 6px -6px black;

    }

    90% {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;;

    }
    100% {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;;

    }
}

.pulse-contact {
    animation: pulseContact 7s infinite;
}



